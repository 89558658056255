import React, { useState } from "react";
import DepositForm from "../../components/depositForm/DepositForm";
import Withdraw from "../../components/withdraw/Withdraw";
import { CiSaveDown1 } from "react-icons/ci";
import { TbTransfer } from "react-icons/tb";
import { MdOutlinePublishedWithChanges } from "react-icons/md";
import { AiOutlineMessage } from "react-icons/ai";
import avatar from "../../assets/images/larry.jpg";
import logo from "../../assets/images/onlylogo.png";
import TransferForm from "../../components/transferForm/TransferForm";
import Notification from "../../components/notification/Notification";
import amazonImg from "../../assets/images/amazonlogo.jpg";
import uberImg from "../../assets/images/uber22.png";
import "./profiletwo.css";

function ProfileTwo() {
    const [activeButton, setActiveButton] = useState(null);

    const toggleContent = (buttonName) => {
      setActiveButton(activeButton === buttonName ? null : buttonName);
    };

  return (
     <div id="profile">
      <div className="profile-info">
        <div className="intro">
            <img className="avatar" src={avatar} alt="personal profile" />
          <h2>Hi, Larry</h2>
        </div>
        <div className="top">
          <div className="card-container">
            {/* <h2>Account Details</h2> */}
            <div class="card">
              <div className="card-top holder-name">
              <p className="bolder">Larry R. Josiah </p>
                <p>00345156182356789</p>
              </div>
              <div className="card-bottom">
                <div className="bottom-left">
                <p className="bolder">Total Balance</p>
                <p className="balance">$27,060,800</p>
                </div>
                <div className="bottom-right">
                  <img src={logo} alt="logo" />
                </div>
              </div>
            </div>       
          </div>
          <div className="notification">
            <h2>Notifications</h2>
            <div className="notification-bottom notification-bottom-two">
            <Notification 
            icon= <AiOutlineMessage />
            heading="Admin"
            message= "We applogise for the inconvenience our system upgrade has caused you.Thank you."
            />

            <Notification 
            heading="Amazon"
            image={amazonImg}
            message= "Upcoming collaboration"
            
            />
            <Notification 
            icon= <CiSaveDown1 />
            heading="Deposit"
            message= "449284437492"
            head= "Transaction ID:"
            money='$27,060,800'

            time='10:15 AM'
            />
            {/* <Notification 
            icon= <CiSaveDown1 />
            heading="Deposit"
            message= "511838378887"
            head= "Transaction ID:"
            money='$2,500,000'
            date='2018/03/22'
            time='2:30 PM'
            /> */}

            <Notification 
            heading='Uber'
            image={uberImg}
            message= 'Use Uber when you want a comfortable and secured ride anywhere within you city.'
            
            />

           <Notification 
            icon= <AiOutlineMessage />
            heading="Admin"
            message= "Welcome Mr. David to the family, we look forward to our fruitful relationship  relationship together."
            />

           {/* <Notification 
            icon= <CiSaveDown1 />
            heading="Deposit"
            message= "355828392831"
            head= "Transaction ID:"
            money='$350,000'
            date='2017/06/05'
            time='8:45 AM'
            /> */}
            {/* <Notification 
            icon= <CiSaveDown1 />
            heading="Deposit"
            message= "489389472132"
            head= "Transaction ID:"
            money='$400,000'
            date='2014/09/18'
            time='11:00 AM'
            />
            <Notification 
            icon= <CiSaveDown1 />
            heading="Deposit"
            message= "547839383903"
            head= "Transaction ID:"
            money='$500,000'
            date='2013/12/10'
            time='4:20 PM'
            />
            <Notification 
            icon= <CiSaveDown1 />
            heading="Deposit"
            message= "490284930290"
            head= "Transaction ID:"
            money='$350,000 '
            date='2013/02/14'
            time='9:00 AM'
            />
            <Notification 
            icon= <CiSaveDown1 />
            heading="Deposit"
            message= "5980182948"
            head= "Transaction ID:"
            money='$550,000 '
            date='2012/10/03'
            time='3:35 PM'
            /> */}

            
            
            </div>
          </div>
        </div>


        <div className="bottom">
        <div className="info-section">
            <h2>Transactions</h2>
            <div className="action-buttons">
              {/* <div className="info-section"> */}
              {/* ====== Transfer====== */}
              <div className="btn-container">
                <button
                  className="action-button"
                  onClick={() => toggleContent("transfer")}
                >
                  <TbTransfer className="icon" />
                  Transfer
                </button>
                {activeButton === "transfer" && (
                  <div className="content transfer">
                    <TransferForm />
                  </div>
                )}
              </div>
              {/* ====== Deposit ====== */}
              <div className="btn-container">
                <button
                  className="action-button"
                  onClick={() => toggleContent("deposit")}
                >
                  <CiSaveDown1 className="icon" />
                  <span className="number">1
                    
                  </span>
                  Deposits
                </button>
                {activeButton === "deposit" && (
                  <div className="content deposit">
                    <DepositForm />
                  </div>
                )}
              </div>
              {/* ====== Withdrawal ====== */}
              <div className="btn-container">
                <button
                  className="action-button"
                  onClick={() => toggleContent("withdraw")}
                >
                  <MdOutlinePublishedWithChanges className="icon" />
                  <span className="number">0</span>
                  Withdrawal
                </button>
                {activeButton === "withdraw" && (
                  <div className="content withdraw">
                    <Withdraw />
                  </div>
                )}
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
              <div className="note container">
                   <h2>NOTE:</h2> 
                <p>
                   All other relevant informations will be available via other channels  and please don't hesitate to contact our team: <span>support-team@slickunionreserve.com</span> 
                </p>
              </div>
      </div>
      <section id="footer" className="footer_profile">
        <div className="footer_wrapper container">
          <div className="bottom">
            <div className="main">
            <div className="left">
              <p>Copyright© {new Date().getFullYear()}</p>
            </div>
            <div className="right">
              <a href="/terms&condistions">Terms & Conditions</a>
              <a href="/privacy&policy">Privacy & Plolicy</a>
            </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ProfileTwo