import React, { useState } from 'react';
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTop from '../../components/scollToTop/ScrollToTop';

function Login() {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // Handles changes in the User ID input
  const handleUserIdChange = (event) => {
    setUserId(event.target.value);
  };

  // Handles changes in the password input
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // Handles the button click and validates the input
  const handleLogin = (event) => {
    event.preventDefault(); // Prevent form submission

    // Validate User ID and password
    if (userId === 'David5122' && password === 'Sailer&45632') {
      // Store authentication status (in a real app, use secure methods)
      localStorage.setItem("authenticated", true);
      navigate('/profile877JGVJHV98V6U6V6C56CRC667O786T6D55CR5C5C5767J8'); // Redirect to the profile page
    } else if (userId === 'Josiah212' && password === 'Larry#R12ss_') {
       // Store authentication status (in a real app, use secure methods)
       localStorage.setItem("authenticated", true);
       navigate('/profile877JGVJHV98V6U6V6C56CRC667O786T6D55CR5C5C5767DJDJ8'); // Redirect to the profile page
    }else {
      alert('Invalid User ID or Password. Please check your credentials and try again.');
    }
  };

  return (
    <div id='form'>
      <form className="home_form" onSubmit={handleLogin}>
        <p>Account Login</p>
        <label>User ID</label>
        <input 
          type="text" 
          value={userId} 
          onChange={handleUserIdChange} 
        />
        <label>Password</label>
        <input 
          type="password" 
          value={password} 
          onChange={handlePasswordChange} 
        />
        <Link to="/">Forgot Password?</Link>
        <button type="submit" className="home_form_button btn">Login</button>
      </form> 
      <ScrollToTop />
    </div>
  );
}

export default Login;