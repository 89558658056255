import React from 'react';
import "./depositForm.css";

function DepositForm() {
  return (
    <div className="deposit-form-container">
    <h2 className="form-title">Deposits</h2>
    <div className="bottom">
    <div className='deposit'>
      <p className="date">Date</p>
      <p className="amount">Amount</p>
    </div>
    <div className='deposit'>
      <p className="date">2024/07/12</p>
      <p className="amount">$27,060,800</p>
    </div>
    <div className='deposit'>
      {/* <p className="date">2018/03/22</p>
      <p className="amount">$350,000</p> */}
    </div>
    <div className='deposit'>
      {/* <p className="date">2017/06/05</p>
      <p className="amount">$400,000</p> */}
    </div>
    <div className='deposit'>
      {/* <p className="date">2014/09/18</p>
      <p className="amount">$450,000</p> */}
    </div>
    <div className='deposit'>
      {/* <p className="date">2013/12/10</p>
      <p className="amount">$500,000</p> */}
    </div>
    <div className='deposit'>
      {/* <p className="date">2013/02/14</p>
      <p className="amount">$350,000</p> */}
    </div>
    <div className='deposit'>
      {/* <p className="date">2012/10/03</p>
      <p className="amount">$550,000 </p> */}
    </div> 
    </div>
  </div>
  )
}

export default DepositForm